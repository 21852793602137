import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';

import Grid from 'components/Layout/Grid';
import Button from 'components/Button';
import AsideMenu from 'components/AsideMenu';
import EntityInfoBuiltInHeader from 'components/EntityInfoBuiltInHeader';
import Table from 'components/Table';
import Search from 'components/Search';

import {
  fetchCustomerClaims,
  updateClaim,
  setCustomerPreload,
} from 'actions/customerClaim';
import { fetchCustomer } from 'actions/customer';
import { fetchCustomersSuggestions } from 'actions/autocomplete';

import { customersAppPath, claimsAppPath } from 'utils/paths';
import { claimsSearchFields } from 'utils/searchFields';

import { headerLinks } from '../../../utils/links';
import { customerClaimsHead } from '../../../utils/tables';

import { addUserPopup, addRefPopup } from '../../../../Claims/components/Popup';
import { defineColorStyle, isCompany } from 'utils/helpers';
import { COMPANY } from 'utils/constants';

class CustomerClaims extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      query: {
        filters: {},
        search: {
          list: {},
          value: '',
        },
        page: 1,
        perPage: 20,
        sort: {
          field: '',
          order: '',
        },
      },
      ref: '',
    };
  }

  componentDidMount() {
    const { query } = this.state;

    this.props.fetchCustomer(this.props.match.params.id);
    this.props.fetchCustomerClaims(this.props.match.params.id, query);
  }

  onSearch = (val) => {
    clearTimeout(this.timeout);

    const query = {
      ...this.state.query,
      search: {
        ...this.state.query.search,
        value: val,
        list: claimsSearchFields,
      },
      page: 1,
    };

    this.setState({ query });
    this.timeout = setTimeout(() => {
      this.updateList(query);
    }, 500);
  };

  onPopupAutocompleteChange = (val) => {
    const query = {
      ...this.state.query,
      search: {
        ...this.state.query.search,
        value: val,
        list: ['first_name', 'last_name'],
        role: 'agent',
      },
      page: 1,
      perPage: 5,
    };
    const key = 'user';

    this.props.fetchCustomersSuggestions(key, query);
  };

  onClickAddUser = (suggestion) => {
    const customerId = this.props.match.params.id;
    const userId = suggestion.id;
    const { targetClaimId } = this.state;
    const params = {
      claim: {
        claimUsersAttributes: [{ userId }],
      },
      userId: customerId,
      id: targetClaimId,
    };

    this.props.updateClaim(customerId, targetClaimId, params).then(() => {
      this.updateList(this.state.query);
    });
  };

  onPaginate = ({ selected }) => {
    const query = { ...this.state.query, page: selected + 1 };
    this.updateList(query);
    this.setState({ query });
  };

  onPopupInputChange = (val) => {
    this.setState({ ref: val });
  };

  onClickAddRef = () => {
    const customerId = this.props.match.params.id;
    const { ref, targetClaimId } = this.state;
    const params = { externalRef: ref };

    this.props.updateClaim(customerId, targetClaimId, params).then(() => {
      this.updateList(this.state.query);
      this.setState({ ref: '' });
    });
  };

  onNewClaimClick = () => {
    const { id } = this.props.match.params;

    this.props.setCustomerPreload(id);
  };

  setClaimId = (id) => {
    this.setState({ targetClaimId: id });
  };

  updateList = (params) => {
    this.props.fetchCustomerClaims(this.props.match.params.id, params);
  };

  render() {
    const { settings } = this.props;
    const rootPath = `${customersAppPath}/${this.props.match.params.id}`;
    const { list, fetching, pagesTotal } = this.props.claim;
    const { firstName, lastName, refId, onfidoVerificationStatus } =
      this.props.customer.data;
    const paginationClass = pagesTotal && pagesTotal > 1 ? '' : 'hidden';
    const searchValue = this.state.query.search.value;
    const loadingDataClass = `${fetching ? 'table-loader' : ''}`;
    const { suggestions } = this.props.autocomplete;
    const { autocomplete } = this.props;
    const addUserlabel = 'Search for user...';
    const addReflabel = 'External ref';
    const labelInside = true;
    const { ref } = this.state;
    const { primaryColor } = settings.data;
    const breadcrumbs = [
      {
        id: 1,
        pathTo: rootPath,
        value: `${firstName || ''} ${lastName || ''} ${refId || ''}`,
      },
    ];

    const paginationActiveClass = isCompany({ company: COMPANY.tie })
      ? 'active-tie'
      : 'active';

    const btnColorStyle = defineColorStyle('botMsgTextColor', settings);

    return (
      <Fragment>
        <EntityInfoBuiltInHeader
          breadcrumbs={breadcrumbs}
          rootPath={rootPath}
          links={headerLinks}
          status={onfidoVerificationStatus}
        />

        <Grid.Main className="main-wrapper-with-aside">
          <Grid.Content className="claims-list-holder">
            <Grid.Controls>
              <div className="">
                <h2 className="h2">Customer claims</h2>
              </div>

              <div className="flex items-center justify-center">
                <Search
                  type="text"
                  name="search"
                  placeholder="Search claims..."
                  wrapperClass="search"
                  className="search-input"
                  onChange={this.onSearch}
                  value={searchValue}
                />

                <Button
                  type="success"
                  pathTo={`${claimsAppPath}/new`}
                  value="+ New claim"
                  className="plus"
                  onClick={this.onNewClaimClick}
                />
              </div>
            </Grid.Controls>

            <div className="table-list-holder">
              <Table
                className="table-сlaimtech"
                primaryColor={primaryColor}
                tHead={customerClaimsHead}
                tBody={list}
                pathBase={claimsAppPath}
                pathField="id"
                onSortChange={this.onSortChange}
                navSubPath={'tasks'}
                trClassName={loadingDataClass}
                loading={fetching}
                titleUser={'Assign to'}
                titleRef={'External reference'}
                autocomplete={autocomplete}
                addUserContent={addUserPopup(
                  addUserlabel,
                  labelInside,
                  suggestions.user || [],
                  this.onPopupAutocompleteChange,
                  this.onClickAddUser
                )}
                addRefContent={addRefPopup(
                  addReflabel,
                  labelInside,
                  ref,
                  this.onPopupInputChange,
                  this.onClickAddRef,
                  { btnColorStyle }
                )}
                cellSpanUser={'Add user'}
                cellSpanRef={'Add ref'}
                setEntityId={this.setClaimId}
                entityType={'claim'}
              />
            </div>

            <ReactPaginate
              pageCount={pagesTotal}
              pageRangeDisplayed={pagesTotal}
              marginPagesDisplayed={10}
              breakLabel={<span>...</span>}
              nextLabel={
                <i className="la-icon la-icon-arrow la-icon-arrow-left" />
              }
              previousLabel={
                <i className="la-icon la-icon-arrow la-icon-arrow-right" />
              }
              breakClassName={'break-me'}
              activeClassName={paginationActiveClass}
              containerClassName={`${paginationClass} pagination flex justify-center landing`}
              subContainerClassName={'pages pagination'}
              onPageChange={this.onPaginate}
            />
          </Grid.Content>

          <Grid.Aside>
            <AsideMenu id={this.props.match.params.id} />
          </Grid.Aside>
        </Grid.Main>
      </Fragment>
    );
  }
}

CustomerClaims.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  fetchCustomerClaims: PropTypes.func.isRequired,
  claim: PropTypes.shape({
    list: PropTypes.array,
    fetching: PropTypes.bool,
    pagesTotal: PropTypes.number,
  }).isRequired,
  autocomplete: PropTypes.shape({
    suggestions: PropTypes.object,
  }).isRequired,
  updateClaim: PropTypes.func.isRequired,
  fetchCustomersSuggestions: PropTypes.func.isRequired,
  setCustomerPreload: PropTypes.func.isRequired,
  fetchCustomer: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    data: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      refId: PropTypes.string,
    }),
  }).isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      primaryColor: PropTypes.string,
    }),
  }).isRequired,
};

CustomerClaims.defaultProps = {};

const mapStateToProps = (state) => ({
  claim: state.customerClaim,
  autocomplete: state.autocomplete,
  customer: state.customer,
  settings: state.settings,
});

const mapDispatchToProps = {
  fetchCustomerClaims,
  updateClaim,
  fetchCustomersSuggestions,
  setCustomerPreload,
  fetchCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerClaims);
