import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Grid from 'components/Layout/Grid';
import Button from 'components/Button';
import Table from 'components/Table';
import Search from 'components/Search';
import { fetchCustomers, updateCustomer } from 'actions/customer';
import { customersAppPath } from 'utils/paths';
import { customersSearchFields } from 'utils/searchFields';
import { listHead } from '../utils/tables';
import { defineColorStyle, isCompany } from 'utils/helpers';
import { COMPANY } from 'utils/constants';
import { addRefPopup } from 'pages/Claims/components/Popup';
class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      query: {
        filters: {},
        search: {
          list: {},
          value: '',
        },
        page: 1,
        perPage: 20,
        sort: {
          field: '',
          order: '',
        },
      },
      ref: '',
    };
    this.timeout = null;
  }
  componentDidMount() {
    this.updateList(this.state.query);
  }
  onSubmit = () => {
    this.updateList(this.state.query);
  };
  onSearch = (val) => {
    clearTimeout(this.timeout);
    const query = {
      ...this.state.query,
      search: {
        ...this.state.query.search,
        value: val,
        list: customersSearchFields,
      },
      // page: 1
    };
    this.setState({ query });
    this.timeout = setTimeout(() => {
      this.updateList(query);
    }, 500);
  };
  onClear = () => {
    const query = { ...this.state.query, filters: {} };
    this.updateList(query);
    this.setState({ query });
  };
  onPaginate = ({ selected }) => {
    const query = { ...this.state.query, page: selected + 1 };
    this.updateList(query);
    this.setState({ query });
  };
  onSortChange = (field, order) => {
    const query = { ...this.state.query, sort: { field, order } };
    this.updateList(query);
    this.setState({ query, list: [] });
  };
  onPopupInputChange = (val) => {
    this.setState({ ref: val });
  };
  onClickAddRef = () => {
    const { ref, targetCustomerId } = this.state;
    const params = { externalRef: ref };
    if (/^ *$/.test(ref)) return;
    this.props.updateCustomer(targetCustomerId, { user: params }).then(() => {
      this.updateList(this.state.query);
      this.setState({ ref: '' });
    });
  };
  setCustomerId = (id) => {
    this.setState({ targetCustomerId: id });
  };
  updateList = (params) => {
    this.props.fetchCustomers(params);
  };
  render() {
    const { settings } = this.props;
    const { list, fetching, pagesTotal } = this.props.customer;
    const activeCustomer = list.filter((customer) => customer.active);
    const { browser } = this.props;
    const paginationClass = pagesTotal && pagesTotal > 1 ? '' : 'hidden';
    const searchValue = this.state.query.search.value;
    const loadingDataClass = `${fetching ? 'table-loader' : ''}`;
    const addReflabel = 'External ref';
    const labelInside = true;
    const { ref } = this.state;
    const { primaryColor } = settings.data;
    const paginationActiveClass = isCompany({ company: COMPANY.tie })
      ? 'active-tie'
      : 'active';

    const btnColorStyle = defineColorStyle('botMsgTextColor', settings);

    return (
      <Fragment>
        <Grid.Main>
          <Grid.Content>
            <Grid.Controls>
              <div>
                <h2 className="h2">All customers</h2>
              </div>
              <div className="search-container flex items-center justify-between">
                <Search
                  type="text"
                  name="search"
                  placeholder="Search customers..."
                  wrapperClass="search"
                  className="search-input"
                  value={searchValue}
                  onChange={this.onSearch}
                />
                <Button
                  type="success"
                  pathTo={`${customersAppPath}/new`}
                  value="+ New customer"
                  className="plus"
                />
              </div>
            </Grid.Controls>
            <div className="table-list-holder">
              <Table
                loading={fetching}
                className="table-сlaimtech"
                tHead={listHead}
                tBody={activeCustomer}
                pathBase={customersAppPath}
                primaryColor={primaryColor}
                pathField="id"
                onSortChange={this.onSortChange}
                trClassName={loadingDataClass}
                addRefContent={addRefPopup(
                  addReflabel,
                  labelInside,
                  ref,
                  this.onPopupInputChange,
                  this.onClickAddRef,
                  { btnColorStyle }
                )}
                cellSpanRef={'Add ref'}
                setEntityId={this.setCustomerId}
                entityType={'customer'}
              />
            </div>
            <ReactPaginate
              pageCount={pagesTotal}
              pageRangeDisplayed={1}
              marginPagesDisplayed={browser.isMobile ? 2 : 5}
              breakLabel={<span>...</span>}
              nextLabel={
                <i className="la-icon la-icon-arrow la-icon-arrow-left" />
              }
              previousLabel={
                <i className="la-icon la-icon-arrow la-icon-arrow-right" />
              }
              breakClassName={'break-me'}
              activeClassName={paginationActiveClass}
              containerClassName={`${paginationClass} pagination flex justify-center landing mt0 mb20`}
              subContainerClassName={'pages pagination'}
              onPageChange={this.onPaginate}
            />
          </Grid.Content>
        </Grid.Main>
      </Fragment>
    );
  }
}
CustomerList.propTypes = {
  fetchCustomers: PropTypes.func.isRequired,
  customer: PropTypes.shape({
    fetched: PropTypes.bool,
    fetching: PropTypes.bool,
    list: PropTypes.array,
    pagesTotal: PropTypes.number,
  }).isRequired,
  browser: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
  updateCustomer: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    data: PropTypes.shape({
      primaryColor: PropTypes.string,
    }),
  }).isRequired,
};
CustomerList.defaultProps = {
  pages: 0,
};
const mapStateToProps = ({ customer, browser, settings }) => ({
  customer,
  pages: customer.pagesTotal,
  browser,
  settings,
});
const mapDispatchToProps = {
  fetchCustomers,
  updateCustomer,
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);
