import _ from 'lodash';
import config from 'utils/config';
import {
  ALLOWED_DOCUMENT_FILE_TYPES,
  ALLOWED_IMAGE_FILE_TYPES,
  ANSWER_TYPES,
  COMPANY,
  ENTITIES,
  LINK_ENTITY_NAME,
  PERMISSION_TYPE,
  PUZZLE_URL,
  SGDomains,
  SYMBOLS_LIMIT,
} from './constants';
import {
  UK_EU_US_PHONE_REGEX,
  UK_DRIVING_LICENCE_REGEX,
  EMAIL_REGEX,
} from '../validation/regexes';
import {
  getNumberValidationError,
  VALIDATION_ERROR_TEXT,
} from './validationErrors';
import { setHeroImage } from 'pages/Login/utils/helpers';
import isPermitted from './isPermitted';

export const isCustomerRole = (arrayOfRoles = []) => {
  const customer = _.find(
    arrayOfRoles,
    (role) =>
      role.name && (role.name === 'customer' || role.name === 'Customer')
  );
  if (customer) {
    return true;
  } else {
    const plainCustomer = _.find(
      arrayOfRoles,
      (role) => role && (role === 'customer' || role === 'Customer')
    );
    if (plainCustomer) {
      return true;
    } else {
      return false;
    }
  }
};

export const isAgentRole = (arrayOfRoles = []) => {
  const agent = _.find(
    arrayOfRoles,
    (role) => role.name && (role.name === 'agent' || role.name === 'Agent')
  );
  if (agent) {
    return true;
  } else {
    const plainAgent = _.find(
      arrayOfRoles,
      (role) => role && (role === 'agent' || role === 'Agent')
    );
    if (plainAgent) {
      return true;
    } else {
      return false;
    }
  }
};

export const isAdminRole = (arrayOfRoles = []) => {
  const admin = _.find(
    arrayOfRoles,
    (role) => role.name && (role.name === 'admin' || role.name === 'Admin')
  );
  if (admin) {
    return true;
  } else {
    const plainAdmin = _.find(
      arrayOfRoles,
      (role) => role && (role === 'admin' || role === 'Admin')
    );
    if (plainAdmin) {
      return true;
    } else {
      return false;
    }
  }
};

export const isSupervisorRole = (arrayOfRoles = []) => {
  const admin = _.find(
    arrayOfRoles,
    (role) =>
      role.name && (role.name === 'supervisor' || role.name === 'Supervisor')
  );
  if (admin) {
    return true;
  } else {
    const plainAdmin = _.find(
      arrayOfRoles,
      (role) => role && (role === 'supervisor' || role === 'Supervisor')
    );
    if (plainAdmin) {
      return true;
    } else {
      return false;
    }
  }
};

export const isUnionMemberRole = (arrayOfRoles = []) => {
  const um = _.find(
    arrayOfRoles,
    (role) =>
      role.name &&
      (role.name === 'union member' || role.name === 'Union Member')
  );
  if (um) {
    return true;
  } else {
    const plainUm = _.find(
      arrayOfRoles,
      (role) => role && (role === 'union member' || role === 'Union Member')
    );
    if (plainUm) {
      return true;
    } else {
      return false;
    }
  }
};

export const isPrimaryRole = (arrayOfRoles = []) => {
  return (
    isAdminRole(arrayOfRoles) ||
    isAgentRole(arrayOfRoles) ||
    isCustomerRole(arrayOfRoles) ||
    isUnionMemberRole(arrayOfRoles)
  );
};

export const isAdnimOrAgentRole = (roles) =>
  isAdminRole(roles) || isAgentRole(roles);

export const clean = (obj) => {
  var propNames = Object.getOwnPropertyNames(obj);
  for (var i = 0; i < propNames.length; i++) {
    var propName = propNames[i];
    if (
      obj[propName].length === 0 ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
};

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const isThompsons = () => {
  return (
    window.location.hostname.split('.')[0] === 'tmp' ||
    window.location.hostname.split('.')[1] === 'thompsons'
  );
};

export const isHorwich = () => {
  return (
    window.location.hostname.split('.')[0] === 'horwich' ||
    window.location.hostname.split('.')[1] === 'holly'
  );
};

export const isAdmiral = () =>
  window.location.hostname.split('.')[0] === 'admiral';

export const isSandgresponse = () =>
  window.location.hostname.split('.')[0] === 'sandgresponse';

export const isXceedance = () =>
  window.location.hostname.split('.')[0] === 'xceedance';

export const isTest = () => window.location.hostname.split('.')[0] === 'test';

export const isCompany = ({ company = COMPANY.test, companies }) => {
  const currentCompany = window.location.hostname.split('.')[0];
  if (companies?.length) {
    return companies.includes(currentCompany);
  } else {
    return currentCompany === company;
  }
};

export const isSG = () =>
  SGDomains.includes(window.location.hostname.split('.')[0]);

export const capitalizeFirstLetter = (text = '') =>
  text.replace(/^\w/, (firstLetter) => firstLetter.toUpperCase());

export const parseFilters = (filters, params = {}) => {
  let queryString = '';
  filters.forEach((el) => {
    if (el.variable && el.filter && el.filterValue) {
      // Backend doesn't proceed value with camelCase

      if (
        el.variable.toLowerCase() === 'created' ||
        el.variable.toLowerCase() === 'modified'
      ) {
        queryString += `&${el.filter}=${el.filterValue.toLowerCase()}`;
      } else if (
        el.variable.toLowerCase() === 'archived' &&
        Object.keys(params).length === 0
      ) {
        queryString += `?${el.variable}_${
          el.filter
        }=${el.filterValue.toLowerCase()}`;
      } else if (
        el.variable.toLowerCase() === 'archived' &&
        Object.keys(params).length > 0
      ) {
        queryString += `&${el.variable}_${
          el.filter
        }=${el.filterValue.toLowerCase()}`;
      } else {
        queryString += `&${el.variable}_${
          el.filter
        }=${el.filterValue.toLowerCase()}`;
      }
    } else if (el.variable && el.filterValue) {
      if (el.variable.toLowerCase() === 'agent_id_in[]') {
        queryString += `&${el.variable}=${el.filterValue}`;
      }
    }
  });

  return queryString;
};

export const setStatus = (status) =>
  status === 'completed form' ? 'completed' : status;

export const disableFieldsTmp = (arr) => {
  if (isThompsons() && isCustomerRole()) {
    const disableField = (el) => {
      if (el.name === 'password' || el.name === 'confirmPassword') {
        el.disabled = false;
      } else {
        el.disabled = true;
      }
    };

    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        for (let j = 0; j < arr[i].length; j++) {
          disableField(arr[i][j]);
        }
      } else {
        disableField(arr[i]);
      }
    }
  }
  return arr;
};

export const checkFileSize = (size, needSize) => {
  if (size > needSize.byteValue) {
    return {
      fileSize: [
        {
          type: 'fileSize',
          message: `I’m sorry, your file cannot be attached as it is larger than ${needSize.title}.`,
        },
      ],
    };
  }

  return false;
};

export const decodeHTML = (content) => {
  let element = document.createElement('div');
  element.innerHTML = content;
  return element.childNodes.length === 0 ? '' : element.childNodes[0].nodeValue;
};

export const setHTML = (answerHtml) => {
  return {
    __html: decodeHTML(answerHtml),
  };
};

export const containsEscapedHTML = (answer) =>
  answer &&
  answer.length > 0 &&
  (answer.includes('&lt') ||
    answer.includes('&quot;') ||
    answer.includes('&#x2F;'))
    ? true
    : false;

export const MAX_ANSWER_LENGTH = 100;

export const isThompsonsPIClaimType = (claimType) => {
  const type = claimType && claimType.name ? claimType.name : null;
  if (
    type &&
    typeof type === 'string' &&
    type.toLowerCase() === 'thompsons pi claim'
  ) {
    return true;
  } else {
    return false;
  }
};

export const readFileAsBufferArray = (file, cb) => {
  const reader = new FileReader();

  // reader.readAsArrayBuffer(file);
  reader.onload = () => {
    // cb(reader.result);
    // var data = reader.result;
    // var array = new Int8Array(file);
    // reader.readAsArrayBuffer(file);
    cb(reader.result);
  };
  reader.onerror = (error) => {
    cb(error);
  };
  reader.readAsArrayBuffer(file);
};

export const validateNumberValue = (val, minNumber, maxNumber) => {
  if ((minNumber || Number(minNumber) === 0) && maxNumber)
    return Number(minNumber) <= Number(val) && Number(val) <= Number(maxNumber);

  if (minNumber && !maxNumber) return Number(minNumber) <= Number(val);

  if (maxNumber && !minNumber) return Number(val) <= Number(maxNumber);
};

export const validateDrivingLicenceValue = (val) => {
  if (val) {
    return UK_DRIVING_LICENCE_REGEX.test(val);
  }

  return true;
};

export const validatePhone = (val) => {
  if (val) {
    const processedPhone = val.replaceAll(' ', '');
    return UK_EU_US_PHONE_REGEX.test(processedPhone);
  }

  return true;
};

export const validateEmail = (email) => {
  if (email) {
    return EMAIL_REGEX.test(email);
  }

  return true;
};

export const validateAnswerByType = (value, question = {}) => {
  // eslint-disable-next-line
  switch (question.answerType?.toLowerCase()) {
    case ANSWER_TYPES.number:
      const { minNumber, maxNumber } = question;
      if ((minNumber || maxNumber) && value) {
        const isValid = validateNumberValue(value, minNumber, maxNumber);
        return isValid ? false : getNumberValidationError(minNumber, maxNumber);
      } else return false;

    case ANSWER_TYPES.drivingLicence:
      if (value && value.length !== 16)
        return [VALIDATION_ERROR_TEXT[[ANSWER_TYPES.drivingLicence]]];

      const isValidDrivingLicense = validateDrivingLicenceValue(value);
      return isValidDrivingLicense
        ? false
        : [VALIDATION_ERROR_TEXT[[ANSWER_TYPES.drivingLicence]]];

    case ANSWER_TYPES.phone:
      const isValidPhone = validatePhone(value);
      return isValidPhone
        ? false
        : [VALIDATION_ERROR_TEXT[[ANSWER_TYPES.phone]]];

    case ANSWER_TYPES.email:
      const isValidEmail = validateEmail(value);
      return isValidEmail
        ? false
        : [VALIDATION_ERROR_TEXT[[ANSWER_TYPES.email]]];

    default:
      return false;
  }
};

export const validateAnswer = (answer = {}, questions = []) => {
  const errors = [];

  const targetQuestion = questions.find((q) => q.id === answer.questionId);

  if (targetQuestion && answer.value) {
    const validationErrors = validateAnswerByType(answer.value, targetQuestion);

    if (validationErrors) {
      errors.push(validationErrors);
    }
  }

  return errors;
};

export const getSettingByName = (name = '', settings = {}) =>
  name && settings.data && settings.data[name];

const claimTaskRegex = /\/tasks\/[0-9]{1,}\/forms/;
export const isClaimTaskPath = (pathname) => claimTaskRegex.test(pathname);

export const arrayHasValues = (array) => array && array.length > 0;

export const dateToUSFormat = (date) =>
  date
    ? new Date(`${date}`)
        .toLocaleString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
        .toString()
    : '';

export const trimStringAnswers = (answers = []) =>
  answers.map((a) => {
    if (typeof a.value !== 'string') return a;
    a.value = a.value.trim();
    return a;
  });

export const areEmptyAnswers = (answers = []) =>
  answers.filter((a) => !a.value);

export const removeEmptyAnswers = (answers = []) =>
  answers.filter((a) => a.value);

export const addFileUploadingErrors = (
  questions = [],
  { videoUploadingError }
) => {
  if (questions.length === 0) return questions;

  let processedQuestions = [...questions];

  if (Object.keys(videoUploadingError).length > 0) {
    const videoUploadingQuestionId = Object.keys(videoUploadingError)[0];
    const targetQuestionIndex = processedQuestions.findIndex(
      (q) => q.id === Number(videoUploadingQuestionId)
    );
    processedQuestions[targetQuestionIndex] = {
      ...processedQuestions[targetQuestionIndex],
      videoUploadingError: videoUploadingError[videoUploadingQuestionId],
    };
  }

  return processedQuestions;
};

export const arrayIsNotEmpty = (array) =>
  array && Array.isArray(array) && array.length > 0;

export const objectIsNotEmpty = (obj) => obj && Object.keys(obj).length > 0;

export const isMediaType = (answerType) =>
  answerType === ANSWER_TYPES.picture ||
  answerType === ANSWER_TYPES.pictures ||
  answerType === ANSWER_TYPES.video ||
  answerType === ANSWER_TYPES.mediaUpload ||
  answerType === ANSWER_TYPES.fileUpload;

export const formatedAcceptedDocumentTypes = ALLOWED_DOCUMENT_FILE_TYPES.map(
  (t) => `.${t}`
).join(', ');

export const formatedAcceptedImageTypes = ALLOWED_IMAGE_FILE_TYPES.map(
  (t) => `.${t}`
).join(', ');

export const formHasErrors = (errors) => {
  let result = false;
  if (errors && errors instanceof Object) {
    const keys = Object.keys(errors);
    if (keys?.length) {
      keys.forEach((key) => {
        if (errors[key]?.length) return (result = true);
      });
    }
  }

  return result;
};

export const setHeroImageStyles = isXceedance()
  ? {
      backgroundImage: `url(${setHeroImage()})`,
      flexGrow: '1',
      width: '1170px',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'absolute',
      right: '2%',
      bottom: '0',
      '@media screen and (minWidth: 1440px)': {
        right: '4%',
      },
      '@media screen and (maxWidth: 600px)': {
        width: '100%',
        right: '0',
      },
    }
  : null;

export const isMobileBrowser = (browser) =>
  browser && browser?.windowSize[0] < 576;

export const runPuzzleChat = ({
  configId,
  customerKey = config.sgPuzzleKey,
  puzzleChatSrc = PUZZLE_URL,
}) => {
  if (!configId || !isSG()) return;

  const puzzleScript = document.createElement('script');
  puzzleScript.src = puzzleChatSrc;
  document.body.append(puzzleScript);
  puzzleScript.onload = function () {
    new window.EUWALoader({
      customerKey,
      configId,
      verticalStarterPosition: 'bottom',
      verticalChatPosition: 'bottom',
      horizontalChatPosition: 'right',
      horizontalStarterPosition: 'right',
    }).load();
    console.log('Puzzle Chat loaded');
  };
};

export const getExternalRef = (partners) => {
  if (!partners) return null;

  const partnerWithRef = partners.find((p) => p.externalRef);

  return partnerWithRef?.externalRef;
};
// Convert text that includes 1) 2) or 1. 2. to an ordered list
export const isListExpression = (text) =>
  text &&
  ((text.includes('1)') && text.includes('2)')) ||
    (text.includes('1.') && text.includes('2.')));

export const convertTextToList = (text) => {
  if (!text) return text;

  let result = null;
  const regex = /\d+[).]/g;

  if (isListExpression(text)) {
    result = text
      .split(regex)
      .filter((i) => i)
      .map((i) => {
        if (i) return i.trim();
      });

    return (
      <ol style={{ paddingLeft: '5px' }}>
        {result.map((k, index) => (
          <li key={index}>{k}</li>
        ))}
      </ol>
    );
  }

  return text;
};

export const processHeaderLinks = ({ links = [], roles = [], tasks }) => {
  if (isPrimaryRole(roles) && !isCompany({ company: COMPANY.sandgresponse }))
    return links;

  let resultLinks = [...links.filter((l) => l)];
  const isAllowerReadContacts = isPermitted(
    roles,
    ENTITIES.contact,
    PERMISSION_TYPE.read
  );
  const isAllowerReadDocuments = isPermitted(
    roles,
    ENTITIES.document,
    PERMISSION_TYPE.read
  );
  const isAllowerReadTasks = isPermitted(
    roles,
    ENTITIES.form,
    PERMISSION_TYPE.read
  );
  const isAllowerReadCorrespondence = isPermitted(
    roles,
    ENTITIES.deliveredNotification,
    PERMISSION_TYPE.read
  );
  const isAllowerUpdateLinkedClaims = isPermitted(
    roles,
    ENTITIES.linkedClaim,
    PERMISSION_TYPE.update
  );

  if (!isAllowerReadContacts) {
    resultLinks = resultLinks.filter(
      (l) => l.value !== LINK_ENTITY_NAME.contacts
    );
  }

  if (!isAllowerReadDocuments) {
    resultLinks = resultLinks.filter(
      (l) => l.value !== LINK_ENTITY_NAME.documents
    );
  }

  if (!isAllowerReadCorrespondence) {
    resultLinks = resultLinks.filter(
      (l) => l.value !== LINK_ENTITY_NAME.correspondence
    );
  }

  if (!isAllowerReadTasks) {
    resultLinks = resultLinks.filter((l) => l.value !== LINK_ENTITY_NAME.tasks);
  }
  if (!isAllowerUpdateLinkedClaims) {
    resultLinks = resultLinks.filter(
      (l) => l.value !== LINK_ENTITY_NAME.linkedClaims
    );
  }

  if (isCompany({ company: COMPANY.sandgresponse })) {
    const excludedTabs = ['Contacts'];
    if (!tasks) excludedTabs.push('Tasks');
    const sgLinks = resultLinks.filter(
      (link) => link && !excludedTabs.includes(link.value)
      // (link) => link && link.value !== 'Contacts' && link.value !== 'Tasks'
    );

    return sgLinks;
  }

  return resultLinks;
};

export const getSymbolsCounterStart = (symbolsLimitSetting) =>
  (symbolsLimitSetting ? symbolsLimitSetting : SYMBOLS_LIMIT) * 0.8;

export const isTextTypeWithLimit = ({
  maxLength,
  type,
  value,
  symbolsLimitSetting,
}) => {
  if (
    (type &&
      typeof type === 'string' &&
      type.toLowerCase() === 'text' &&
      maxLength &&
      Number.parseInt(maxLength) &&
      Number.parseInt(maxLength) > 0) ||
    value?.length >= getSymbolsCounterStart(symbolsLimitSetting)
  ) {
    return true;
  } else {
    return false;
  }
};

export const defineColor = (textColorSetting, defaultColor = 'white') =>
  textColorSetting ? textColorSetting : defaultColor;

export const defineColorStyle = (settingName, settings) => {
  if (!settings || !settingName) return {};

  const textColorSetting = getSettingByName(settingName, settings);
  return textColorSetting ? { color: textColorSetting } : {};
};

export const getTransparentBtnColor = ({ btnColorStyle = null }) => {
  if (!btnColorStyle) return {};

  const result = { ...btnColorStyle };

  if (result.color === '#ffffff' || result.color === 'white') {
    result.color = '#000000';
  }

  return result;
};
