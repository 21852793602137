import React from 'react';
import { COMPANY, SELECTED_CLAIMS_LIMIT } from 'utils/constants';
import {
  isAdminRole,
  isAgentRole,
  isCompany,
  isCustomerRole,
  isHorwich,
  isSupervisorRole,
  isThompsons,
  isThompsonsPIClaimType,
  isUnionMemberRole,
} from 'utils/helpers';

export const getNonSelectableRowsIds = (rows = [], selectedRows = []) => {
  let result = [];

  rows.forEach((row) => {
    const isPresent = selectedRows.find((i) => row.id === i.id);
    if (!isPresent) result.push(row);
  });

  return result.map((r) => r.id);
};

export const getSelectedClaimCounter = (selectedClaims = []) =>
  selectedClaims.length > 0 ? (
    <span className="title-wrap-counter">
      {selectedClaims.length} / {SELECTED_CLAIMS_LIMIT}
    </span>
  ) : null;

export const isFiltering = (filters) => {
  let result = false;
  if (filters && Array.isArray(filters) && filters.length) {
    filters.forEach((filter) => {
      const { filterValue, filter: filterKey } = filter;
      if (
        filterValue &&
        filterKey &&
        filterValue !== 'open' &&
        filterKey !== 'eq'
      ) {
        result = true;
      }
    });
  }

  return result;
};

export const getClaims = async ({
  customQuery,
  customFilters = [],
  fetchClaims,
  session,
  query,
}) => {
  const { roles, id } = session.data;

  if (isThompsons()) {
    if (isAdminRole(roles) || isUnionMemberRole(roles)) {
      await fetchClaims(customQuery || query, [
        {
          variable: 'status',
          filter: 'eq',
          filterValue: 'open',
        },
        customFilters,
      ]);
    } else {
      await fetchClaims(customQuery || query, [
        {
          variable: 'users_id',
          filter: 'eq',
          filterValue: id + '',
        },
        customFilters,
      ]);
    }
  } else if (
    isAdminRole(roles) ||
    isUnionMemberRole(roles) ||
    isSupervisorRole(roles) ||
    isAgentRole(roles) ||
    isCustomerRole(roles)
  ) {
    await fetchClaims(customQuery || query, [
      {
        variable: 'status',
        filter: 'eq',
        filterValue: 'open',
      },
      customFilters,
    ]);
  } else {
    await fetchClaims(customQuery || query, [
      {
        variable: 'status',
        filter: 'eq',
        filterValue: 'open',
      },
      customFilters,
    ]);
  }
};

export const getNavSubPath = (claimType) => {
  if (isCompany({ company: COMPANY.sandgresponse })) {
    return '';
  } else if (isThompsons()) {
    return !isThompsonsPIClaimType(claimType) ? 'tasks' : '';
  } else {
    return 'tasks';
  }
};

export const createClaimRequiredField = () => {
  if (isHorwich()) return true;

  return false;
};

export const companyBasedValidation = (validations = {}) => {
  let result = { ...validations };

  if (isHorwich()) {
    result = {
      ...result,
      externalRef: [
        {
          type: 'isRequired',
          message: 'Please add external claim reference.',
        },
      ],
    };
  }

  return result;
};
