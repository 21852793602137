import React from 'react';

import Input from 'components/Input';
import Field from 'components/Field';
import Button from 'components/Button';
import Autocomplete from 'components/Autocomplete';

import { PopupSuggestion, SuggestionSelected } from './Suggestion';

export const addUserPopup = (
  label,
  labelInside,
  suggestions,
  onChange,
  onClick
) => (
  <div className="flex justify-between items-center direction-column">
    <Autocomplete
      Suggestion={PopupSuggestion}
      SuggestionSelected={SuggestionSelected}
      labelInside={labelInside}
      label={label}
      suggestions={suggestions}
      onChange={onChange}
      className={'windowed'}
      onSuggestionClick={onClick}
    />
  </div>
);

export const addRefPopup = (
  label,
  labelInside,
  value,
  onChange,
  onClick,
  additionalConfig
) => {
  const btnColorStyle = additionalConfig?.btnColorStyle;

  return (
    <div className="flex justify-between items-center direction-column">
      <Input
        labelInside={labelInside}
        label={label}
        onChange={onChange}
        className={'windowed'}
        name={'ref'}
        value={value}
        type="text"
      />
      <div className="popup-complete flex">
        <Button
          type="success"
          className="text-capitalize plus"
          value="Save"
          onClick={onClick}
          style={btnColorStyle}
        />
      </div>
    </div>
  );
};

export const SimplePopup = ({
  isOpen,
  title,
  btnContent,
  options,
  onChange,
  onCancel,
  onClick,
  defaultValue,
}) =>
  isOpen && (
    <div
      onClick={(e) => e.stopPropagation(e)}
      className="popup-global popup-simple flex direction-column"
      role="button"
      tabIndex={0}
    >
      <div className="popup-inner flex direction-column justify-between">
        <div className="popup-inner-top flex direction-column">
          <h2>{title}</h2>
          <Field
            element="select"
            type="select"
            options={options}
            name="popup-select"
            onChange={onChange}
            defaultValue={defaultValue}
          />
        </div>

        <div className="popup-inner-footer flex justify-end">
          <Button
            type="normal"
            onClick={onCancel}
            value="Cancel"
            className="edit"
          />
          <Button
            type="success"
            onClick={onClick}
            value={btnContent}
            className="edit"
          />
        </div>
      </div>
    </div>
  );

const Popup = {
  addUserPopup,
  addRefPopup,
  SimplePopup,
};

export default Popup;
